import qs from "qs"

class PopupWindow {
  constructor(id, url, window, options = {}) {
    this.id = id
    this.url = url
    this.window = window
    this.options = options
  }

  toParams(query) {
    const q = query.replace(/^\??\//, "")

    return q.split("&").reduce((values, param) => {
      const [key, value] = param.split("=")

      values[key] = value

      return values
    }, {})
  }

  open() {
    const { url, id, window: win, options } = this

    const y = win.top.outerHeight / 2 + win.top.screenY - options.height / 2
    const x = win.top.outerWidth / 2 + win.top.screenX - options.width / 2
    const allOptions = { ...options, top: y, left: x }
    const windowOptions = Object.keys(allOptions)
      .map(k => `${k}=${allOptions[k]}`)
      .join(", ")
    this.window = window.open(url, id, windowOptions)
  }

  close() {
    this.cancel()
    this.window.close()
  }

  poll() {
    this.promise = new Promise((resolve, reject) => {
      this._iid = window.setInterval(() => {
        try {
          const popup = this.window

          if (!popup || popup.closed !== false) {
            this.close()

            reject(new Error("The popup was closed"))

            return
          }

          if (popup.location.search) {
            // we slice the location search to remove the first question mark
            // otherwise parsing would fail
            const { code, state } = qs.parse(popup.location.search.slice(1))
            resolve({ code, state })

            this.close()
          }
        } catch (error) {
          /*
           * Ignore DOMException: Blocked a frame with origin from accessing a
           * cross-origin frame.
           */
        }
      }, 200)
    })
  }

  cancel() {
    if (this._iid) {
      window.clearInterval(this._iid)
      this._iid = null
    }
  }

  then(...args) {
    return this.promise.then(...args)
  }

  catch(...args) {
    return this.promise.then(...args)
  }

  static open(...args) {
    const popup = new this(...args)

    popup.open()
    popup.poll()

    return popup
  }
}

export default PopupWindow
