import React, { useContext, useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"
import { Text, Box, Flex } from "rebass"
import { ReactComponent as DownChevron } from "../../../assets/svg/down-chevron.svg"
import { ReactComponent as UpChevron } from "../../../assets/svg/up-chevron.svg"

import { AccountContext } from "../../../context/account"
import styled from "@emotion/styled"

const StyledPopoverItem = styled(Text)`
  font-weight: 500;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: ${props => props.theme.colors.link};
    color: white;
  }

  svg {
    path {
      fill: black;
    }
  }

  &:hover {
    svg {
      path {
        fill: white;
      }
    }
  }
`

const NewAccountItem = styled(Text)`
  cursor: pointer;
  color: ${props => props.theme.colors.link};
  fontweight: 500;

  &:hover {
    color: white;
    background-color: ${props => props.theme.colors.link};
  }
`

const EnvItem = styled(Text)`
  background-color: ${props => props.theme.colors.lightest};
  cursor: pointer;

  ${props =>
    props.selected &&
    `
    font-weight: bold;
  `}

  &:hover {
    background-color: ${props => props.theme.colors.light};
  }
`

const AccountsPopover = ({ hide }) => {
  const containerRef = useRef(null)
  const {
    accounts,
    selectedEnvironment,
    handleSelectAccountOrEnv,
  } = useContext(AccountContext)
  const [collapse, setCollapse] = useState({})

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        hide()
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [containerRef])

  return (
    <>
      <Box
        sx={{
          top: 0,
          left: 0,
          position: "fixed",
          width: "100%",
          height: "100%",
        }}
      />
      <Box
        ref={containerRef}
        sx={{
          zIndex: 1002,
          width: "220px",
          fontSize: "14px",
          borderRadius: "5px",
          background: "white",
          top: "80px",
          left: "25px",
          position: "absolute",
          boxShadow: "popover",
        }}
      >
        <Box width="100%">
          {accounts.length &&
            accounts.map(account => (
              <>
                <StyledPopoverItem py={2} px={3}>
                  <Text
                    sx={{ flexBasis: "50%" }}
                    onClick={() => {
                      handleSelectAccountOrEnv(account.id, null)
                      hide()
                    }}
                  >
                    {account.name}
                  </Text>
                  <Flex
                    sx={{
                      flexBasis: "50%",
                      justifyContent: "flex-end",
                      height: "100%",
                    }}
                    onClick={() =>
                      setCollapse(prev => ({
                        [account.id]: prev[account.id] ? null : account,
                      }))
                    }
                  >
                    {collapse[account.id] ? (
                      <Flex sx={{ width: "10px" }}>
                        <UpChevron />
                      </Flex>
                    ) : (
                      <Flex sx={{ width: "10px" }}>
                        <DownChevron />
                      </Flex>
                    )}
                  </Flex>
                </StyledPopoverItem>
                {collapse[account.id]
                  ? collapse[account.id].environments?.map((env, i) => (
                      <EnvItem
                        py={2}
                        px={3}
                        key={i}
                        selected={selectedEnvironment.id === env.id}
                        onClick={e => {
                          handleSelectAccountOrEnv(account.id, env.id)
                          hide()
                          e.stopPropagation()
                        }}
                      >
                        {env.name}
                      </EnvItem>
                    ))
                  : null}
              </>
            ))}
          <Box
            sx={{
              bg: "#F9F9F9",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          >
            <NewAccountItem
              onClick={() => {
                hide()
                navigate("/a/new-account")
              }}
              px={3}
              py={2}
            >
              + New account
            </NewAccountItem>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default AccountsPopover
