import React, { useContext, useEffect, useState } from "react"
import Medusa from "../../services/api"
import { githubRequest } from "../../services/request"
import axios from "axios"
import GithubIconSvg from "../../assets/github.svg"
import PopupWindow from "./"
import { Button, Flex, Text } from "rebass"
import Spinner from "../spinner"
import styled from "@emotion/styled"
import { GitContext } from "../../context/git"

const GITHUB_CLIENT_ID = process.env.GITHUB_CLIENT_ID || "Iv1.e95d101a8042c953"

const GITHUB_APP_URL =
  process.env.NODE_ENV === "development"
    ? "https://github.com/apps/medusa-cloud-dev"
    : "https://github.com/apps/medusa-cloud"

const GithubButton = styled(Button)`
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
`

const GithubIcon = styled.img`
  display: inline-block;
  margin-bottom: 0;
  margin-right: 8px;
  height: 18px;
`

const GitHubLogin = ({ loading }) => {
  const { gitConnect, toggleLoading } = useContext(GitContext)
  const [installed, setInstalled] = useState(true)

  const onSuccess = async data => {
    if (!data.code) {
      return new Error("'code' not found")
    }

    await gitConnect("github", data)
  }

  const onFailure = error => {
    toggleLoading("github", false)
  }

  const handleGithub = async () => {
    toggleLoading("github", true)
    const { data } = await Medusa.auth.createCsrfToken({
      provider: "github",
    })

    const params = {
      client_id: GITHUB_CLIENT_ID,
      scope: "user",
      state: data.csrf,
    }

    const qstring = Object.keys(params)
      .map(k => `${k}=${params[k]}`)
      .join("&")

    const loginUrl = `https://github.com/login/oauth/authorize?${qstring}`

    const popup = PopupWindow.open("github-authorize", loginUrl, window, {
      height: 1000,
      width: 600,
    })

    popup.then(
      data => onSuccess(data),
      error => onFailure(error)
    )
  }

  return (
    <Flex flexDirection="column" sx={{ width: "100%" }}>
      <GithubButton variant="cta" onClick={handleGithub}>
        {loading ? (
          <Flex
            alignItems={"center"}
            sx={{
              height: "100%",
              flex: "none",
            }}
          >
            <Spinner height={"65%"} dark={false} />
          </Flex>
        ) : (
          <>
            <GithubIcon alt="GitHub" src={GithubIconSvg} />
            GitHub
          </>
        )}
      </GithubButton>
      {!installed && (
        <Text sx={{ fontSize: "12px", marginTop: [2] }}>
          You have not yet installed the Medusa Github App.{" "}
          <a href={GITHUB_APP_URL} target="_blank">
            Please click here.
          </a>
        </Text>
      )}
    </Flex>
  )
}

export default GitHubLogin
