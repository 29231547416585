import React, { useContext, useEffect, useState } from "react"
import { Flex, Heading, Text, Button, Box, Image } from "rebass"
import styled from "@emotion/styled"
import InputField from "../input"
import Select from "../select"

import Thrash from "../../assets/svg/thrash.svg"
import GitHubLogin from "../popup/github"
import { GitContext } from "../../context/git"
import Spinner from "../spinner"

const removeNullish = obj =>
  Object.entries(obj).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})

const GITHUB_APP_URL =
  process.env.NODE_ENV === "development"
    ? "https://github.com/apps/medusa-cloud-dev"
    : "https://github.com/apps/medusa-cloud"

const StyledVariable = styled(Box)`
  display: grid;
  grid-template-columns: 200px 200px;
  grid-column-gap: 16px;
`

const StyledSource = styled(Box)`
  display: grid;
  grid-template-columns: 280px 180px;
  grid-column-gap: 16px;
`

const StyledAddButton = styled(Button)`
  background: transparent;
  border: none;
  box-shadow: none;
  color: "blue";
  padding: 0;
  margin: 0;
  width: 100px;
  font-weight: 700;
  font-size: 14px;
  display: inline-block;
  text-align: left;
  cursor: pointer;
  &:hover {
    background: transparent;
    border: none;
    box-shadow: none;
    color: "blue";
  }
`

const ThrashButton = styled(Button)`
  box-shadow: none;
  padding: 0;
  margin-left: 1rem;
  &:hover {
    box-shadow: none;
  }
`

const LiveEnvironment = ({ submit, creatingEnvironment }) => {
  const {
    repositories,
    repository,
    branches,
    installations,
    gitConnect,
    fetchRepositories,
    fetchBranches,
    loading,
    provider,
    token,
    clearGit,
  } = useContext(GitContext)

  const [name, setName] = useState("master")
  const [selectedRepository, setSelectedRepository] = useState(null)
  const [branch, setBranch] = useState("")
  const [installation, setInstallation] = useState(null)
  const [variables, setVariables] = useState([{ key: "", value: "" }])

  const updateKey = (e, index) => {
    const tmp = Array.from(variables)
    tmp[index] = { key: e.target.value, value: tmp[index].value }
    setVariables(tmp)
  }

  const updateValue = (e, index) => {
    const tmp = Array.from(variables)
    tmp[index] = {
      key: tmp[index].key,
      value: e.target.value,
    }
    setVariables(tmp)
  }

  const handleRemove = variable => {
    const newArr = variables.filter(v => {
      return v !== variable
    })
    setVariables(newArr)
  }

  const handleAdd = () => {
    setVariables(oldArr => [...oldArr, { key: "", value: "" }])
  }

  // constructs the uri for the source
  // format: [provider]:[organization]/[repository]:[branch]
  const constructUri = () => {
    switch (provider) {
      case "github": {
        return `github:${repository.owner.login}/${repository.name}:${branch}`
      }
      default:
        return ""
    }
  }

  const handleSubmit = async () => {
    let requestData = {
      name: repository.name,
      env_name: name,
      uri: constructUri(),
      token,
      provider,
      branch,
    }

    const vars = variables.filter(v => v.key && v.value)

    if (vars.length) {
      requestData.env_vars = vars
    }

    await submit(requestData)
  }

  useEffect(() => {
    if (installation) {
      fetchRepositories(installation)
    }
  }, [installation])

  useEffect(() => {
    if (selectedRepository && selectedRepository !== "Select a repository") {
      fetchBranches(selectedRepository)
    }
  }, [selectedRepository])

  return (
    <>
      <Flex flexDirection="column" p="0 2rem">
        <Flex flexDirection={"column"}>
          <Heading mb={3}>Choose Git provider</Heading>
          {token && (
            <Text>
              <Text sx={{ fontSize: "12px", marginBottom: [2] }}>
                Still don't see you Git repository?{" "}
                <a
                  href={GITHUB_APP_URL}
                  target="_blank"
                  onClick={() => clearGit()}
                >
                  Add another Github organization.
                </a>
              </Text>
            </Text>
          )}
          {installations.length ? (
            <>
              <StyledSource>
                <Text fontWeight={600}>Organization</Text>
              </StyledSource>
              <StyledSource mt={3}>
                <Select
                  selectStyle={{ flex: "100% 0 0 !important", padding: "8px" }}
                  placeholder="Select an organization"
                  onChange={e => setInstallation(e.currentTarget.value)}
                  options={installations.map(inst => ({
                    label: inst.account.login,
                    value: inst.id,
                  }))}
                />
              </StyledSource>
            </>
          ) : null}
          {loading[provider] && loading.type === "repository" && (
            <Flex justifyContent="center" mt={4}>
              <Spinner dark maxWidth="50px" />
            </Flex>
          )}
          {repositories.length ? (
            <>
              <StyledSource mt={3}>
                <Text fontWeight={600}>Repository</Text>
                <Text fontWeight={600}>Branch</Text>
              </StyledSource>
              <StyledSource mt={3}>
                <Select
                  selectStyle={{ flex: "100% 0 0 !important", padding: "8px" }}
                  placeholder="Select a repository"
                  onChange={e => setSelectedRepository(e.currentTarget.value)}
                  options={repositories.map(repo => ({
                    label: repo.name,
                    value: repo.id,
                  }))}
                />
                <Select
                  selectStyle={{
                    flex: "100% 0 0 !important",
                    padding: "8px",
                  }}
                  disabled={branches.length > 0 ? false : true}
                  placeholder="Select a branch"
                  onChange={e => setBranch(e.currentTarget.value)}
                  options={branches.map(b => ({
                    label: b.name,
                    value: b.name,
                  }))}
                />
              </StyledSource>
              <StyledSource mt={3}>
                <Text fontWeight={600}>Environment name</Text>
              </StyledSource>
              <StyledSource mt={3}>
                <InputField
                  placeholder="Environment name"
                  value={name}
                  type="text"
                  onChange={({ currentTarget }) => setName(currentTarget.value)}
                  inputStyle={{ fontWeight: "600 !important" }}
                />
              </StyledSource>
            </>
          ) : null}
          <Flex
            sx={{
              display: installations.length ? "none !important" : "flex",
            }}
          >
            <GitHubLogin loading={loading["github"]} />
            <Box sx={{ width: "20px" }} />
          </Flex>
        </Flex>
        <Flex flexDirection={"column"} mt={4}>
          {selectedRepository ? (
            <>
              <Text fontWeight="600">Environment variables</Text>
              <Flex flexDirection="column" my={3}>
                <StyledVariable mb={3}>
                  <Text fontWeight={600}>Key</Text>
                  <Text fontWeight={600}>Value</Text>
                </StyledVariable>
                {variables.map((variable, i) => {
                  return (
                    <Flex key={i} mb={3}>
                      <StyledVariable>
                        <InputField
                          placeholder="Key"
                          defaultValue={variable.key}
                          type="text"
                          onChange={e => updateKey(e, i)}
                          inputStyle={{ fontWeight: "600 !important" }}
                        />
                        <InputField
                          placeholder="Value"
                          defaultValue={variable.value}
                          type="text"
                          onChange={e => updateValue(e, i)}
                        />
                      </StyledVariable>
                      <ThrashButton onClick={() => handleRemove(variable)}>
                        <Image src={Thrash} height={"16px"} />
                      </ThrashButton>
                    </Flex>
                  )
                })}
                <StyledAddButton onClick={handleAdd} sx={{ color: "blue" }}>
                  + Add
                </StyledAddButton>
              </Flex>
            </>
          ) : null}
        </Flex>
      </Flex>
      <Button
        onClick={() => handleSubmit()}
        variant="cta"
        ml={"2rem"}
        disabled={!selectedRepository || !branch || !name}
        loading={creatingEnvironment}
      >
        Connect
      </Button>
    </>
  )
}

export default LiveEnvironment
