import React, { useState } from "react"
import { Box, Flex, Text } from "rebass"
import { ReactComponent as FeedbackIcon } from "../../assets/svg/feedback.svg"
import Medusa from "../../services/api"
import Button from "../button"
import TextArea from "../textarea"

const Feedback = ({ forceClose }) => {
  const [feedback, setFeedback] = useState("")
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleClose = e => {
    e.stopPropagation()
    forceClose()
  }
  const handleChange = e => {
    setFeedback(e.target.value)
    setError(false)
  }
  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)
    Medusa.feedback
      .create({ message: feedback })
      .then(data => {
        setFeedback("")
        setIsSubmitted(true)
        setIsLoading(false)
      })
      .catch(err => {
        setError(true)
        setIsLoading(false)
      })
  }

  return (
    <Box
      sx={{
        width: "340px",
        height: "200px",
        fontSize: "14px",
        borderRadius: "5px",
      }}
      as="form"
      onSubmit={handleSubmit}
    >
      <Flex height="100%" flexDirection="column">
        {isSubmitted ? (
          <Flex
            mt={4}
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Box mb={2}>
              <FeedbackIcon />
            </Box>
            <Text mb={2} color="medusa-80" variant="h3">
              Thank you for helping us improve
            </Text>
            <Text variant="body.default">Your feedback matters</Text>
          </Flex>
        ) : (
          <>
            {error && (
              <Flex
                mt={2}
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Text variant="small.default" color="danger">
                  Something wrong happened, please try again later
                </Text>
              </Flex>
            )}
            <Box flex="1">
              <TextArea
                pt={3}
                px={3}
                sx={{ height: "100%" }}
                textAreaProps={{
                  sx: {
                    boxShadow: "none",
                    resize: "none",
                    "&:focus": { boxShadow: "none" },
                    flex: 1,
                  },
                }}
                onClick={e => e.stopPropagation()}
                placeholder="Help us improve your experience."
                onChange={handleChange}
                value={feedback}
              />
            </Box>
            <Flex
              px={3}
              py={3}
              sx={{
                borderTopWidth: "1px",
                borderTopStyle: "solid",
                borderTopColor: "ui-50",
              }}
              bg="ui-25"
              justifyContent="space-between"
            >
              <Button variant="light" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                loading={isLoading}
                disabled={!feedback}
                onClick={e => e.stopPropagation()}
                type="submit"
                variant="cta"
              >
                Send
              </Button>
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  )
}

export default Feedback
