import React, { useContext, useEffect, useState } from "react"
import { Box, Image, Flex, Button, Heading, Text } from "rebass"
import Bash from "../bash"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import LiveEnvironment from "./live-environment"

import Typography from "../typography"
import Cross from "../../assets/svg/cross.svg"
import Logo from "../../assets/svg/new-logo.svg"
import { AccountContext } from "../../context/account"

const Padding = css`
  padding: 0 2rem;
`

const BashContainer = styled.div`
  margin-bottom: 1.5rem;
`

const StyledClose = styled(Button)`
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
`

const StyledModal = styled(Box)`
  opacity: ${props => (props.open ? "1" : "0")};
  visibility: ${props => (props.open ? "visible" : "hidden")};
  z-index: 9999;
  transition: ${props => (props.open ? "all 0.2s linear" : "")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: white;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

const StyledInner = styled(Box)`
  max-width: 700px;
  min-width: 0;
  width: 700px;
  transition: all 0.3s linear;
  transform: ${props => (props.open ? "scale(1)" : "scale(0.85)")};
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
`

const StyledText = styled(Text)`
  ${Padding};
  margin-bottom: 1.5rem;
  ${Typography.Medium};
`

const Setup = ({ modal, close }) => {
  const [content, setContent] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const { handleSubmitEnvironment } = useContext(AccountContext)

  const handleLiveEnvSubmit = async data => {
    setLoading(true)
    try {
      await handleSubmitEnvironment(data)
      close()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    switch (modal.content) {
      case "local":
        setContent(
          <>
            <Heading sx={{ padding: "0 2rem" }} fontSize="32px" mb={4}>
              Link local project
            </Heading>
            <StyledText>
              Linking your local Medusa project enables you to use Medusa Cloud
              with a locally running Medusa server. Doing so makes it possible
              to view test orders, manage orders etc.
            </StyledText>
            <StyledText>
              To link your local project open your terminal and move to the root
              directory of your project. If you haven’t already make sure to
              login via the Medusa CLI.
            </StyledText>
            <BashContainer>
              <Bash commands={["medusa login"]} />
            </BashContainer>
            <StyledText>
              To link you project use the link command of the Medusa CLI.
            </StyledText>
            <BashContainer>
              <Bash commands={["medusa link"]} />
            </BashContainer>
            <StyledText>
              The Medusa link command will create a user in your local database
              that can be used to interact with Medusa Cloud. After linking you
              can view local orders in Medusa Cloud.
            </StyledText>
            <Button variant="cta" onClick={close} mt={2} ml={"2rem"}>
              Back
            </Button>
          </>
        )
        break
      case "live":
        setContent(
          <>
            <Heading sx={{ padding: "0 2rem" }} fontSize="32px" mb={4}>
              Setup your live environment
            </Heading>
            <LiveEnvironment
              submit={data => handleLiveEnvSubmit(data)}
              creatingEnvironment={loading}
            />
          </>
        )
        break
      case "scratch":
        setContent(
          <>
            <Heading sx={{ padding: "0 2rem" }} fontSize="32px" mb={4}>
              Start from scratch
            </Heading>
            <StyledText>
              Getting started with Medusa is really simple. All you have to do
              is to install the Medusa CLI and run the new command.
            </StyledText>
            <BashContainer>
              <Bash
                commands={[
                  "yarn global add @medusajs/medusa-cli",
                  "medusa new",
                ]}
              />
            </BashContainer>
            <StyledText>
              Once your Medusa project is set up you can link your local project
              to leverage the Medusa Cloud features. To do so run the link
              command.
            </StyledText>
            <BashContainer>
              <Bash commands={["medusa link"]} />
            </BashContainer>
            <StyledText>
              To learn more about how to customize your Medusa project checkout
              our documentation and guides.
            </StyledText>
            <Flex mt={4}>
              <Button onClick={close} ml={"2rem"}>
                Back
              </Button>
              <a
                href="https://docs.medusa-commerce.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button onClick={close} variant="cta" ml={"1rem"}>
                  Go to Docs
                </Button>
              </a>
            </Flex>
          </>
        )
        break
      default:
        setContent(undefined)
    }
  }, [modal])
  return (
    <StyledModal open={modal.open}>
      <StyledInner open={modal.open} my={5}>
        <Flex
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "0 0 0 2rem",
          }}
          mb={5}
        >
          <Image src={Logo} />
          <StyledClose onClick={() => close()}>
            <Image src={Cross} height="15px" />
          </StyledClose>
        </Flex>
        {content}
      </StyledInner>
    </StyledModal>
  )
}

export default Setup
