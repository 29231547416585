import React, { useContext, useEffect, useState } from "react"
import { Flex, Box, Text } from "rebass"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import Collapsible from "react-collapsible"
import { useFlags } from "gatsby-plugin-launchdarkly"

import Toggle from "../toggle"
import { Container, LogoContainer } from "./elements"
import { LocalDevContext } from "../../context/local-dev"
import { AccountContext } from "../../context/account"
import AccountsPopover from "./accounts-popover"
import { InterfaceContext } from "../../context/interface"
import SetupEnv from "../setup-env"

const StyledItemContainer = styled(Link)`
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 8px;
  align-items: center;
  border-radius: 5pt;
  cursor: pointer;

  text-decoration: none;
  color: black;
  height: 30px;

  svg {
    max-height: 20px;
    max-width: 20px;
  }

  img {
    max-height: 18px;
    max-width: 18px;
    margin: 0px;
  }

  [fill*="red"] {
    fill: #454545;
  }

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}

  &:hover {
    ${props =>
      !props.active &&
      !props.disabled &&
      `
      background-color: #e0e0e059;
    `}
  }

  &.active {
    background-color: #e0e0e0;
    font-weight: 600;
  }
`

const Sidebar = ({}) => {
  const [showAccounts, setShowAccounts] = useState(false)
  const { selectedAccount, selectedEnvironment } = useContext(AccountContext)
  const flags = useFlags()

  const { isActive: isLocalDev, toggleActive: toggleLocalDev } = useContext(
    LocalDevContext
  )

  const handleLocalDev = () => {
    toggleLocalDev()
  }

  return (
    <Container fontSize={1} fontFamily={"body"} pb={3} pt={4} px={4}>
      <Flex mx={-2} alignItems="center">
        {showAccounts && (
          <AccountsPopover hide={() => setShowAccounts(false)} />
        )}
        <LogoContainer mx={2} />
        <Box
          mx={1}
          sx={{ cursor: "pointer", flex: 1 }}
          onClick={() => setShowAccounts(true)}
        >
          <Text fontWeight="500">{selectedAccount?.name || "-"}</Text>
          <Text sx={{ fontSize: "12px", lineHeight: 1, fontWeight: "bold" }}>
            {isLocalDev
              ? "Local development"
              : selectedEnvironment
              ? selectedEnvironment.name
              : "-"}
          </Text>
        </Box>
      </Flex>
      <Flex py={4} mx={-1} flexDirection="column" flex={1}>
        <Flex mb={3} flexDirection="row">
          <StyledItemContainer
            to="/a"
            activeClassName="active"
            style={{ width: "100%" }}
          >
            <img src="https://img.icons8.com/ios/50/000000/purchase-order.png" />
            <Text ml={3} variant="nav">
              Home
            </Text>
          </StyledItemContainer>
        </Flex>
        <Collapsible
          transitionTime={150}
          transitionCloseTime={150}
          trigger={
            <StyledItemContainer
              to="/a/orders"
              activeClassName="active"
              partiallyActive
              disabled={
                selectedAccount?.environments?.length === 0 && !isLocalDev
              }
            >
              <img src="https://img.icons8.com/ios/50/000000/purchase-order.png" />
              <Text ml={3} variant="nav">
                Orders
              </Text>
            </StyledItemContainer>
          }
        >
          <StyledItemContainer
            to="/a/swaps"
            activeClassName="active"
            partiallyActive
            disabled={
              selectedAccount?.environments?.length === 0 && !isLocalDev
            }
          >
            <Flex alignItems="center" pl={3} width="100%">
              <Text ml="14px" variant="nav" fontSize="12px">
                Swaps
              </Text>
            </Flex>
          </StyledItemContainer>
          <StyledItemContainer
            to="/a/returns"
            activeClassName="active"
            partiallyActive
            disabled={
              selectedAccount?.environments?.length === 0 && !isLocalDev
            }
          >
            <Flex alignItems="center" pl={3} width="100%">
              <Text ml="14px" variant="nav" fontSize="12px">
                Returns
              </Text>
            </Flex>
          </StyledItemContainer>
        </Collapsible>
        <Collapsible
          transitionTime={150}
          transitionCloseTime={150}
          trigger={
            <StyledItemContainer
              to="/a/products"
              activeClassName="active"
              partiallyActive
              disabled={
                selectedAccount?.environments?.length === 0 && !isLocalDev
              }
            >
              <img src="https://img.icons8.com/ios/50/000000/product--v1.png" />
              <Text ml={3} variant="nav">
                Products
              </Text>
            </StyledItemContainer>
          }
        >
          <StyledItemContainer
            to="/a/collections"
            activeClassName="active"
            partiallyActive
            disabled={
              selectedAccount?.environments?.length === 0 && !isLocalDev
            }
          >
            <Flex alignItems="center" pl={3} width="100%">
              <Text ml="14px" variant="nav" fontSize="12px">
                Collections
              </Text>
            </Flex>
          </StyledItemContainer>
        </Collapsible>
        <StyledItemContainer
          to="/a/customers"
          activeClassName="active"
          partiallyActive
          disabled={selectedAccount?.environments?.length === 0 && !isLocalDev}
        >
          <img src="https://img.icons8.com/ios/50/000000/gender-neutral-user.png" />
          <Text ml={3} variant="nav">
            Customers
          </Text>
        </StyledItemContainer>
        <StyledItemContainer
          to="/a/discounts"
          activeClassName="active"
          partiallyActive
          disabled={selectedAccount?.environments?.length === 0 && !isLocalDev}
        >
          <img src="https://img.icons8.com/ios/50/000000/discount.png" />
          <Text ml={3} variant="nav">
            Discounts
          </Text>
        </StyledItemContainer>
        <StyledItemContainer
          to="/a/gift-cards"
          activeClassName="active"
          partiallyActive
          disabled={selectedAccount?.environments?.length === 0 && !isLocalDev}
        >
          <img src="https://img.icons8.com/ios/50/000000/gift-card.png" />
          <Text ml={3} variant="nav">
            Gift Cards
          </Text>
        </StyledItemContainer>
        <Flex mt={3} mb={2}>
          <Box pl={1}>
            <Toggle value={true} onChange={handleLocalDev} disabled={true} />
          </Box>
          <Text
            fontWeight={isLocalDev && "bold"}
            color={isLocalDev && "#c44c34"}
            ml={"15px"}
            variant="nav"
          >
            {isLocalDev ? "In local development" : "Enable local development"}
          </Text>
        </Flex>
        {flags.cloudDeployment && (
          <StyledItemContainer
            to="/a/new-env"
            activeClassName="active"
            partiallyActive
          >
            <img src="https://img.icons8.com/material-outlined/50/000000/merge-git.png" />
            <Text ml={3} variant="nav">
              Setup new environment
            </Text>
          </StyledItemContainer>
        )}
        <StyledItemContainer
          to="/a/settings"
          activeClassName="active"
          partiallyActive
          disabled={selectedAccount?.environments?.length === 0 && !isLocalDev}
        >
          <img src="https://img.icons8.com/ios/50/000000/settings--v1.png" />
          <Text ml={3} variant="nav">
            Settings
          </Text>
        </StyledItemContainer>
      </Flex>
    </Container>
  )
}

export default Sidebar
