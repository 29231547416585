import styled from "@emotion/styled"
import { Flex, Box } from "rebass"

export const LogoContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 9999pt;
  background-color: ${props => props.theme.colors.gray};
`

export const InlineLogoContainer = styled(Flex)`
  [fill*="red"] {
    fill: #454545;
  }
`

export const Container = styled(Flex)`
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.light};
  flex-direction: column;
`
