import React, { useState } from "react"
import { Button, Flex, Text, Image } from "rebass"
import styled from "@emotion/styled"
import Clipboard from "../../assets/svg/clipboard.svg"

const StyledFlex = styled(Flex)`
  font-family: "Roboto Mono", sans;
  font-weight: 600;
  border-radius: 6px;
  padding: 12px 24px;
  justify-content: space-between;
  min-height: 58px;
  align-items: center;
  width: 100%;
`

const StyledMessage = styled(Text)`
  visibility: ${props => (props.copied ? "visible" : "hidden")};
  opacity: ${props => (props.copied ? "1" : "0")};
  color: white;
  margin-right: 1rem;
  user-select: none;
  transition: all 0.2s ease-in;
  transform: translateX(15px);
  transform: ${props =>
    props.copied ? "translateX(0px)" : "translateX(15px)"};
`

const Bash = ({ commands }) => {
  const [isCopied, setIsCopied] = useState(false)
  const handleCopy = () => {
    navigator.clipboard.writeText(commands.join(" && "))
    setIsCopied(true)
  }

  return (
    <StyledFlex bg={"medusa"} fontSize={"medium"}>
      <Flex flexDirection="column">
        {commands.map((command, i) => {
          return (
            <Flex key={i}>
              <Text sx={{ userSelect: "none" }} color={"#C9C67B"} mr={3}>
                $
              </Text>
              <Text color={"white"}>{command}</Text>
            </Flex>
          )
        })}
      </Flex>
      <Flex alignItems="center">
        <StyledMessage copied={isCopied} fontSize="small">
          Copied
        </StyledMessage>
        <Button p={2} onClick={() => handleCopy()}>
          <Image height="12px" src={Clipboard} />
        </Button>
      </Flex>
    </StyledFlex>
  )
}

export default Bash
