import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import Sidebar from "../sidebar"

import { Container, Main, Content, Body } from "./elements"
import TopBar from "./topbar"

import "./layout.css"
import Setup from "../setup"
import { InterfaceContext } from "../../context/interface"
import { AccountContext } from "../../context/account"
import { Flex } from "rebass"
import Spinner from "../spinner"

const Layout = ({ children }) => {
  const { modal, onCloseModal } = useContext(InterfaceContext)
  const { loading } = useContext(AccountContext)

  return (
    <Container>
      <Setup modal={modal} close={onCloseModal} />
      <Main>
        <Sidebar />
        <Content fontFamily={"body"} pb={4} pl={4} pr={4}>
          <TopBar />
          {loading ? (
            <Flex
              justifyContent="center"
              alignItems="center"
              height="500px"
              width="100%"
              px={4}
            >
              <Flex maxWidth="1200px">
                <Spinner dark sx={{ height: "50px", width: "50px" }} />
              </Flex>
            </Flex>
          ) : (
            <Body pl={3} pr={3} pb={3} mx="auto">
              {children}
            </Body>
          )}
        </Content>
      </Main>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
