import React from "react"
import { Box } from "rebass"
import styled from "@emotion/styled"

const StyledToggle = styled(Box)`
  position: relative;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  .checkbox {
    display: none;
  }
  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 200ms ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 14px;
      padding: 0;
      line-height: 14px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: "";
      text-transform: uppercase;
      padding-left: 10px;
      background-color: #c44c34;
      color: #fff;
    }
  }

  .inner:after {
    content: "";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  .switch {
    display: block;
    width: 14px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 11px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 200ms ease-in 0s;
  }
  .checkbox:checked + .label {
    .inner {
      margin-left: 0;
    }
    .switch {
      right: 0px;
    }
  }

  ${props =>
    props.disabled &&
    `
    
  cursor: not-allowed;
  pointer-events: none;
  
  .inner {
    cursor: not-allowed;
    
    &:before, &:after {
      opacity: 0.5;
    }
  }
  
  .switch {
    cursor: not-allowed;
    
    &:before, &:after {
      opacity: 0.5;
    }
  }
  `}
`

const Toggle = ({ value, onChange, ...rest }) => {
  return (
    <StyledToggle {...rest}>
      <input
        checked={value}
        onChange={e => onChange(e.target.checked)}
        type="checkbox"
        className="checkbox"
        name="toggleSwitch"
        id="toggleSwitch"
      />
      <label className="label" htmlFor="toggleSwitch">
        <span className="inner" />
        <span className="switch" />
      </label>
    </StyledToggle>
  )
}

export default Toggle
