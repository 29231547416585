import React, { useContext } from "react"
import { Flex, Text, Heading, Button, Box } from "rebass"
import { InterfaceContext } from "../../context/interface"
import styled from "@emotion/styled"
import { useFlags } from "gatsby-plugin-launchdarkly"

import ButtonStyles from "../../theme/buttons"

const StyledCtaButton = styled(Button)`
  ${ButtonStyles.cta}
  height: 35px;
  max-width: 100%;
  padding: 0;
`

const StyledContainer = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  min-height: 286px;
  padding: 15px;
`

const StyledGrid = styled(Box)`
  display: grid;
  justify-items: center;
  grid-template-columns: 288px 288px 288px;
  grid-column-gap: 32px;
`

const SetupEnv = () => {
  const { onOpenModal } = useContext(InterfaceContext)
  const { cloudDeployment } = useFlags()

  return (
    <StyledGrid>
      {cloudDeployment && (
        <StyledContainer variant="loginCard">
          <Flex flexDirection="column">
            <Heading as="h2" mb={3}>
              Setup live environment
            </Heading>
            <Text fontSize={2}>
              I already have a Medusa project that I want to deploy and connect.
            </Text>
          </Flex>
          <StyledCtaButton variant="cta" onClick={() => onOpenModal("live")}>
            Connect repository
          </StyledCtaButton>
        </StyledContainer>
      )}
      <StyledContainer variant="loginCard">
        <Flex flexDirection="column">
          <Heading as="h2" mb={3}>
            Link local project
          </Heading>
          <Text fontSize={2}>
            I have a local project that I want to link to Medusa Cloud.
          </Text>
        </Flex>
        <StyledCtaButton variant="cta" onClick={() => onOpenModal("local")}>
          Link project
        </StyledCtaButton>
      </StyledContainer>
      <StyledContainer variant="loginCard">
        <Flex flexDirection="column">
          <Heading as="h2" mb={3}>
            Start from scratch
          </Heading>
          <Text fontSize={2}>
            I am just getting started with Medusa and need a starter project to
            work from
          </Text>
        </Flex>
        <StyledCtaButton variant="cta" onClick={() => onOpenModal("scratch")}>
          Get started
        </StyledCtaButton>
      </StyledContainer>
    </StyledGrid>
  )
}

export default SetupEnv
